import React, { useContext, useRef, useState } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { motion } from 'framer-motion';
import { getHighlights } from '../utils/getHighlights';

export default function Highlights(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;

    const highlights = getHighlights(contentModel);

    const [showAll, setShowAll] = useState<boolean>(false);

    const highlightRef = useRef<HTMLDivElement>(null);

    function getDisclaimers(equipItem: SSPContentVO) {
        let disclaimers: Array<string> = [];

        if (equipItem.asModel.has('disclaimers')) {
            disclaimers = equipItem.asModel.objlist('disclaimers').map((keyValue: any) => keyValue.Value);
        }
        if (equipItem.asModel.has('packageItems')) {
            equipItem.asModel.reflist('packageItems').forEach(mdds => {
                disclaimers = [
                    ...new Set([
                        ...disclaimers,
                        ...mdds.asModel.objlist('disclaimers').map((keyValue: any) => keyValue.Value),
                    ]),
                ];
            });
        }
        return disclaimers;
    }

    return (
        <div id='highlights'>
            <motion.div
                className={`highlights${showAll ? '' : ' hide'}`}
                transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                }}
                variants={{ shown: { height: 'auto' }, hidden: { height: 435 } }}
                initial={showAll ? 'shown' : 'hidden'}
                animate={showAll ? 'shown' : 'hidden'}
                onAnimationComplete={() => {
                    if (!showAll && highlightRef.current && highlightRef.current?.getBoundingClientRect().top < 0) {
                        window.scrollTo({
                            top: highlightRef.current.offsetTop - window.innerHeight * 0.5,
                            behavior: 'smooth',
                        });
                    }
                }}
            >
                <strong>Ausstattungshighlights</strong>
                {highlights.map((equipItem, index) => (
                    <div className='highlight-item' key={`highlight-${index}`}>
                        <p>{equipItem.asModel.str('layertitle')}</p>
                        <p
                            className='more-information'
                            onClick={() => {
                                setOverlay(store, {
                                    title: 'Highlight',
                                    baseContent: equipItem,
                                    packageContents: equipItem.asModel.reflist('packageItems'),
                                    disclaimerList: getDisclaimers(equipItem),
                                    isVideo:
                                        equipItem.asModel.reflist('assets').length > 0
                                            ? equipItem.asModel.reflist('assets')[0].asAsset.fileProperties.fileType ===
                                              'mp4'
                                            : false,
                                    headline: equipItem.asModel.str('layertitle'),
                                    description: equipItem.asModel.str('description')
                                        ? equipItem.asModel.str('description')
                                        : '',
                                    media:
                                        equipItem.asModel.reflist('assets').length > 0
                                            ? equipItem.asModel.reflist('assets')[0].asAsset.url
                                            : undefined,
                                });
                            }}
                        >
                            Mehr erfahren
                        </p>
                    </div>
                ))}

                <div className={`highlights-shadow${showAll ? '' : ' hide'}`} />
            </motion.div>
            <div className='highlights-get-all' ref={highlightRef}>
                <button className={`highlights-switch${showAll ? '' : ' hide'}`} onClick={() => setShowAll(!showAll)}>
                    <svg>
                        <use xlinkHref='#assistants-button-arrow' />
                    </svg>
                </button>
            </div>
        </div>
    );
}
