import React, { useEffect, useState } from 'react';
import Page from '../Page';
import { PageChildrenEnum } from '../../enums';
import './styles.scss';
import MapContainer from '../MapContainer';
import { mapPointsMap, mapPointsTravel } from './mapPoints';

export default function Map(props: { travel: boolean }): JSX.Element {
    const header = document.querySelector<HTMLDivElement>('div.page-header');
    const footer = document.querySelector<HTMLDivElement>('div.page-footer');
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const [scrollY, setScrollY] = useState<number>(0);
    const [mapPointIndex, setMapPointIndex] = useState<number>(-1);
    const mapPoints = props.travel ? mapPointsTravel : mapPointsMap;

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollY(window.scrollY));
    }, []);

    return (
        <>
            <Page child={props.travel ? PageChildrenEnum.Back : PageChildrenEnum.Map}>
                <div className='map'>
                    <MapContainer
                        width={window.innerWidth}
                        heightNormal={window.innerHeight * 0.47}
                        heightFullscreen={
                            window.innerHeight - (header?.offsetHeight || 0) - (footer?.offsetHeight || 0)
                        }
                        travel={props.travel}
                        fullscreen={fullscreen}
                        setFullscreen={setFullscreen}
                        setMapPointIndex={setMapPointIndex}
                    />
                    {!fullscreen && !props.travel && (
                        <div className='map-text'>
                            <h1 className='big-headline'>ÜBERSICHTSPLAN DER AUTOSTADT</h1>
                            <p className='map-description'>
                                Erkunden Sie Marken des Volkswagen Konzerns in Pavillons, Ausstellungen und bei
                                Probefahrten, entdecken Sie unsere Shops und genießen Sie köstliche Gerichte und
                                Getränke in unseren Restaurants. Ihren Weg von Attraktion zu Attraktion finden Sie über
                                unsere interaktive Karte.
                            </p>
                            <div className='legend dark'>
                                <div>01</div>
                                <span>
                                    Piazza und WelcomeDesk
                                    <br />
                                    Check-in AbholerWelt
                                    <br />
                                    Treffpunkt Führungen
                                </span>
                            </div>
                            {mapPointsMap.slice(1, 26).map((point, index) => (
                                <div className={`legend ${point.color}`} key={`legend-map-point-${index}`}>
                                    <div>{point.number < 10 ? '0' + point.number : point.number}</div>
                                    <span>{point.title}</span>
                                </div>
                            ))}
                            <br />
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#Parkplatz' />
                                    </svg>
                                </div>
                                <span>Parkplatz</span>
                            </div>
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#Rollstuhlparkplatz' />
                                    </svg>
                                </div>
                                <span>Behindertenparkplatz</span>
                            </div>
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#eLaden' />
                                    </svg>
                                </div>
                                <span>E-Ladestationen</span>
                            </div>
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#Garderobe' />
                                    </svg>
                                </div>
                                <span>Garderobe & Schließfächer</span>
                            </div>
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#FotoSpot' />
                                    </svg>
                                </div>
                                <span>Foto-Spots</span>
                            </div>
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#Restaurant' />
                                    </svg>
                                </div>
                                <span>Restaurant</span>
                            </div>
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#Shop' />
                                    </svg>
                                </div>
                                <span>Volkswagen Zubehör Shop</span>
                            </div>
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#GeschenkeShop' />
                                    </svg>
                                </div>
                                <span>moment! Marken und Erlebnis-Shop</span>
                            </div>
                            <div className='legend blue'>
                                <div>
                                    <svg>
                                        <use xlinkHref='#Spielplatz_Icon' />
                                    </svg>
                                </div>
                                <span>Spielplatz</span>
                            </div>
                        </div>
                    )}
                    {!fullscreen && props.travel && (
                        <div className='map-text'>
                            <h1 className='big-headline'>INTERAKTIVE ANREISEKARTE</h1>
                            <p>
                                Die Navigationslinien dieser Grafik verdeutlichen den Weg vom Hauptbahnhof zur Autostadt
                                oder vom Parkplatz zur Autostadt.
                            </p>
                        </div>
                    )}
                    {!fullscreen && (
                        <div className={`map-cover${scrollY < window.innerHeight * 0.47 * 0.5 ? ' legend' : ''}`} />
                    )}
                </div>
            </Page>
            {mapPointIndex >= 0 && mapPointIndex < mapPoints.length && (
                <div className={`map-overlay ${mapPoints[mapPointIndex].color}`} style={{ height: window.innerHeight }}>
                    <div className='map-overlay-content'>
                        {mapPoints[mapPointIndex].color == 'blue' && <meta name='theme-color' content='#00ABE9' />}
                        {mapPoints[mapPointIndex].color == 'dark' && <meta name='theme-color' content='#21307D' />}
                        {mapPoints[mapPointIndex].color == 'green' && <meta name='theme-color' content='#00e17d' />}
                        <button className='close-overlay-button' onClick={() => setMapPointIndex(-1)}>
                            <svg>
                                <use xlinkHref='#close' />
                            </svg>
                        </button>
                        <div>
                            <h1 className='big-headline upper line'>
                                {mapPoints[mapPointIndex].titleOverlay || mapPoints[mapPointIndex].title}
                            </h1>
                            {mapPoints[mapPointIndex].text}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
