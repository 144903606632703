import React, { useContext } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';

export default function PickUpPickUp(): JSX.Element {
    const store = useContext(SspProviderContext);

    return (
        <div id='pick-up'>
            <svg className='svg-img'>
                <use xlinkHref='#openBoxSVG' />
            </svg>
            <h1 className='big-headline center'>DAS BESTE KOMMT IMMER ZUM SCHLUSS</h1>
            <h2 className='middle-headline line center'>AUF ZUR ABHOLUNG</h2>
            <p>
                Sie haben Ihre Checkliste abgehakt, die Anreise geplant und können nun kaum erwarten, Ihren Neuwagen
                abzuholen? Dann nichts wie los. Wir freuen uns auf Ihren Besuch!
            </p>
            <svg className='chevron-fixed'>
                <use xlinkHref='#double-chevron-right' />
            </svg>
            <h1 className='big-headline line center'>ANMELDUNG VOR ORT</h1>
            <p>
                Vor Ort angekommen betreten Sie zunächst den großen Eingangsbereich der Autostadt, die Piazza. Melden
                Sie sich dort am Welcome Desk an.
            </p>
            <div className='dashed-line'>
                <div className='green-dot' />
            </div>
            <svg className='svg-img small clock'>
                <use xlinkHref='#clockSVG' />
            </svg>
            <p className='clock'>90 min</p>
            <p>
                Bitte finden Sie sich spätestens <b>90 Minuten</b> vor Ihrem Abholtermin zur Anmeldung ein, damit wir
                Ihre mitgebrachten Unterlagen prüfen und Ihren Tagesplan final abstimmen können.
            </p>
            <div className='dashed-line'>
                <div className='green-dot' />
            </div>
            <svg className='svg-img small'>
                <use xlinkHref='#valueCardSVG' />
            </svg>
            <p>
                Sie erhalten zudem Ihre Tageskarten, das <b>Wertguthaben</b> für den Volkswagen Zubehör-Shop und unseren
                „moment! Marken & Erlebnis-Shop" sowie den Premiumrabatt für die Autostadt Restaurants.
            </p>
            <div className='dashed-line'>
                <div className='green-dot' />
            </div>
            <svg className='svg-img small'>
                <use xlinkHref='#boardingCardSVG' />
            </svg>
            <p className='extra-margin'>
                Damit Sie trotz der Aufregung und Vorfreude stets den Überblick behalten, bekommen Sie von uns eine{' '}
                <b>BoardingCard</b> mit allen Zeiten zum Tagesablauf und zur Fahrzeugauslieferung.
            </p>
            <div className='dashed-line'>
                <div className='green-dot' />
            </div>
            <div className='blue-circle' />
            <h1 className='big-headline center extra-margin'>UND DANN IST ES PLÖTZLICH SOWEIT!</h1>
            <p>
                Auf seiner Reise zur ersten Besitzerin oder zum ersten Besitzer entsteht ein neuer Volkswagen entlang
                vieler Stationen, an denen er mit viel Sorgfalt und Liebe zum Detail fertiggestellt, geprüft und
                perfektioniert wird.
                <br />
                <br />
                Nun übergeben wir ihn in Ihre Obhut und freuen uns, dass Sie diesen ganz besonderen Glanzmoment bei uns
                in der Autostadt erleben.
                <br />
                <br />
                Wir wünschen Ihnen, dass Ihr Besuch und Ihre ersten gemeinsamen Kilometer ein unvergessliches Erlebnis
                bleiben.
                <br />
                <br />
                <b className='greeting'>Allzeit gute Fahrt!</b>
            </p>
        </div>
    );
}
