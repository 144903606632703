import React, { useRef } from 'react';
import './styles.scss';

export default function VideoPlayer(props: { link: string; filetype: string }): JSX.Element {
    const videoRef = useRef<HTMLVideoElement>(null);

    return (
        <div className='video-player'>
            <video
                ref={videoRef}
                disablePictureInPicture={true}
                controlsList={'nodownload'}
                autoPlay={true}
                playsInline={true}
                muted={true}
                loop={true}
            >
                <source src={props.link} type={'video/' + props.filetype} />
            </video>
        </div>
    );
}
