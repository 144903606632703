import React, { useContext } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import VideoPlayer from '../../VideoPlayer';
import SSPAssetDataSetAccessor from 'sspcontentext/dist/model/accessor/SSPAssetDataSetAccessor';
// import {setOverlay} from "../../../utils/SspProvider/dispatchFunctions";
import Default from '../../../assets/images/Default.jpg';

export default function Overview(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    // const vehicleData = contentModel.ref('vehicle_data').asModel;
    // const engine = contentModel.ref('engine').asModel;
    // const propulsionType = contentModel.ref('vehicle_data').contentType;

    function renderMediaAsset(mediaAsset?: SSPAssetDataSetAccessor) {
        if (mediaAsset?.fileFamily === 'image') {
            return <img src={mediaAsset.url} alt='your-car' />;
        } else if (mediaAsset?.fileFamily === 'video') {
            return <VideoPlayer link={mediaAsset.url} filetype={mediaAsset.fileProperties.fileType} />;
        }
        return <></>;
    }

    let overviewAsset;
    if (contentModel.has('driving_scene_asset')) {
        overviewAsset = contentModel.ref('driving_scene_asset');
    } else {
        overviewAsset = contentModel.reflist('header_assets').find(asset => {
            return asset.asModel.isinStrlist('tags', 'front:left:false:');
        });
    }

    // const disclaimer = (<div className='in-circle' onClick={() => setOverlay(store, {disclaimer: 1})}>1</div>)
    // const disclaimerEfficiency = (<div className='in-circle' onClick={() => setOverlay(store, {disclaimer: 2})}>2</div>)

    // function getOverviewText() {
    //     switch (propulsionType) {
    //         case "VehicleDataElectric":
    //             return (
    //                 <>
    //                     {`${vehicleData.str('model')} | ${engine.str('fuel')} | `}
    //                     {`Stromverbrauch kombiniert: ${vehicleData.float('nedc_consumption_combined').toLocaleString('de-DE')} kWh/100 km`}
    //                     {disclaimer}
    //                     {` und CO₂-Emission kombiniert ${vehicleData.integer('wltp_co2_combined')} g/km`}
    //                     {disclaimer}
    //                     {` | Effizienzklasse: ${vehicleData.str('nedc_energy_efficiency')}`}
    //                     {disclaimerEfficiency}
    //                 </>
    //             )
    //         case "VehicleDataHybrid":
    //             return (
    //                 <>
    //                     {`Kraftstoffverbrauch kombiniert
    //                                 ${vehicleData.float(
    //                         'nedc_consumption_combined').toLocaleString('de-DE')
    //                     || vehicleData.float('wltp_consumption_combined').toLocaleString('de-DE')} l/100 km Super 95; `}
    //                     {`Stromverbrauch kombiniert ${vehicleData.float('nedc_consumption_electric_combined').toLocaleString('de-DE') || vehicleData.integer('wltp_consumption_electric_combined')} kWh/100 km`}
    //                     {disclaimer}
    //                     {`• CO₂ Emissionen kombiniert ${vehicleData.integer('nedc_co2_combined') || vehicleData.integer('wltp_co2_combined')} g/km CO2 `}
    //                     {disclaimer}
    //                     {`• Effizienzklasse ${vehicleData.str('nedc_energy_efficiency')}`}
    //                     {disclaimerEfficiency}
    //                 </>
    //             )
    //         case "VehicleDataGas":
    //             return (
    //                 <>
    //                     {`${vehicleData.str('model')} | ${engine.str('fuel')} | `}
    //                     {`Kraftstoffverbrauch kombiniert: ${vehicleData.float('nedc_consumption_combined').toLocaleString('de-DE')} kg/100 km`}
    //                     {disclaimer}
    //                     {` und CO₂-Emission kombiniert ${vehicleData.integer('wltp_co2_combined')} g/km`}
    //                     {disclaimer}
    //                     {` | Effizienzklasse: ${vehicleData.str('nedc_energy_efficiency')}`}
    //                     {disclaimerEfficiency}
    //                 </>
    //             )
    //         default:
    //             return (
    //                 <>
    //                     {`${vehicleData.str('model')} | ${engine.str('fuel')} | `}
    //                     {`Kraftstoffverbrauch kombiniert: ${vehicleData.float('nedc_consumption_combined').toLocaleString('de-DE')} l/100 km`}
    //                     {disclaimer}
    //                     {` und CO₂-Emission kombiniert ${vehicleData.integer('wltp_co2_combined')} g/km`}
    //                     {disclaimer}
    //                     {` | Effizienzklasse: ${vehicleData.str('nedc_energy_efficiency')}`}
    //                     {disclaimerEfficiency}
    //                 </>
    //             )
    //     }
    // }

    return (
        <div id='overview'>
            <div className='overview-img'>
                {overviewAsset ? renderMediaAsset(overviewAsset?.asAsset) : <img src={Default} alt='Default Image' />}
            </div>
            <div className='overview-welcome'>
                <div className='overview-logo'>
                    <div className='logo-line' />
                    <svg>
                        <use xlinkHref='#vw-logo' />
                    </svg>
                    <div className='logo-line' />
                </div>
                <span>
                    <strong>Ihre digitale</strong>
                </span>
                <span>
                    <strong>Mappe</strong>:
                </span>
                {contentModel.str('title') !== undefined ? (
                    <span>
                        {contentModel
                            .str('title')
                            .split(' ')
                            .map((s, i) => (
                                <>
                                    {i != 0 ? ' ' : ''}
                                    <span key={i}>{s}</span>
                                </>
                            ))}
                    </span>
                ) : (
                    <span>--</span>
                )}
                {/*<div className='overview-welcome-text'>{getOverviewText()}</div>*/}
            </div>
            <div className='overview-text' id='overview-text'>
                <h1>
                    <b>Ihr</b> Fahrzeug
                </h1>
                <p>
                    Sie haben Ihren ganz individuellen Volkswagen konfiguriert. Und zwar genau so, wie er für Sie sein
                    soll. Davon können Sie sich im Exposé nun noch einmal überzeugen.
                    <br />
                    <br />
                    Viel Freude mit Ihrer digitalen Mappe und Ihrem einzigartigen Neuwagen!
                </p>
            </div>
        </div>
    );
}
