export const myPromise = (condition, ...args) => {
    const promise = new Promise((resolve, reject) => {
        if (condition) {
            if (args && args.length !== 0) {
                resolve(...args);
            } else {
                reject('Argruments cannot be empty');
            }
        } else {
            reject('Something when wrong!!!');
        }
    });

    return promise;
};
