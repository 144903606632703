import React, { ReactElement } from 'react';

type MapPoint = {
    number: number;
    color: string;
    position: { left: string; top: string; width?: string; aspectRatio?: string };
    type: string;
    title: string;
    titleOverlay?: string;
    text: ReactElement | string;
};

export const mapPointsMap: MapPoint[] = [
    {
        number: 1,
        color: 'dark',
        position: { left: '55.7%', top: '47.5%', width: '3.1%', aspectRatio: '0.7' },
        type: 'Ausstellungsräume',
        title: 'PIAZZA & WELCOME DESK',
        text: (
            <>
                Der Welcome Desk auf der Piazza ist Ihre erste Anlaufstelle, wenn Sie die Autostadt besuchen. Hier
                beantworten wir Ihre Fragen und unterstützen Sie bei Buchungen aller Art. Zudem können Sie am Welcome
                Desk Eintrittskarten sowie Tickets für die Turmfahrt, Fahrten auf unseren GeländeParcours oder unserem
                SicherheitsParcours sowie Führungen und Veranstaltungen kaufen.
                <h1 className='big-headline upper line' style={{ marginTop: 28 }}>
                    CHECK-IN ABHOLERWELT
                </h1>
                <h1 className='big-headline upper line' style={{ marginTop: 28 }}>
                    TREFFPUNKT FÜHRUNGEN
                </h1>
            </>
        ),
    },
    {
        number: 2,
        color: 'dark',
        position: { left: '65.2%', top: '47%' },
        type: 'Orientierung in der Autostadt',
        title: 'Gepäckabgabe',
        text: 'Möglichkeit zur Gepäckabgabe innerhalb der Autostadt.',
    },
    {
        number: 3,
        color: 'dark',
        position: { left: '60.1%', top: '16%', width: '4.5%', aspectRatio: '0.7' },
        type: 'Orientierung in der Autostadt',
        title: 'KundenCenter',
        text: 'Die Autostadt beherbergt das größte Auslieferungszentrum für Neuwagen weltweit: das KundenCenter – der Ort, an dem Sie Ihrem Fahrzeug das erste Mal live begegnen! Während wir Ihre Bühne vorbereiten, können Sie sich aktuelle Modelle von Volkswagen auf dem Marktplatz anschauen oder im Volkswagen Zubehör Shop die passenden Accessoires für Ihr Auto shoppen.',
    },
    {
        number: 4,
        color: 'dark',
        position: { left: '55.3%', top: '4.6%' },
        type: 'Orientierung in der Autostadt',
        title: 'Autotürme',
        text: '',
    },
    {
        number: 5,
        color: 'dark',
        position: { left: '78.3%', top: '12.5%' },
        type: 'Orientierung in der Autostadt',
        title: 'Ausfahrt',
        text: '',
    },
    {
        number: 6,
        color: 'dark',
        position: { left: '80.7%', top: '13.7%' },
        type: 'Orientierung in der Autostadt',
        title: 'Gepäckbereitstellung',
        titleOverlay: 'Gepäck-bereitstellung',
        text: '',
    },
    {
        number: 7,
        color: 'dark',
        position: { left: '87%', top: '27.5%' },
        type: 'Orientierung in der Autostadt',
        title: 'Mietwagenrückgabe',
        titleOverlay: 'Mietwagen-rückgabe',
        text: 'Ihren Mietwagen, z.B. der Anbieter Avis, Sixt und Europcar, können Sie täglich zwischen 09:00 Uhr und 16:45 Uhr voll geladen bzw. voll getankt beim Autostadt-Team am Parkinghäuschen auf dem Kurzzeitparkplatz abgeben. Nutzen Sie zur Orientierung bitte vor Ort die Beschilderung "Mietwagenannahme".',
    },
    {
        number: 8,
        color: 'dark',
        position: { left: '74.2%', top: '39.5%', aspectRatio: '0.7' },
        type: 'Orientierung in der Autostadt',
        title: 'Start WerkTour',
        text: '',
    },
    {
        number: 9,
        color: 'dark',
        position: { left: '43%', top: '81%' },
        type: 'Orientierung in der Autostadt',
        title: 'Hauptbahnhof',
        text: (
            <>
                Vom Hauptbahnhof Wolfsburg erreichen Sie die Autostadt in wenigen Minuten zu Fuß über die StadtBrücke.
                Ein kurzer Spaziergang führt Sie direkt zur Piazza.
                <br />
                <br />
                In der Autostadt WelcomeLounge im Eingangsbereich des Wolfsburger Bahnhofs nehmen wir Ihr Gepäck gleich
                nach der Ankunft mit dem Zug zwischen 08:00 Uhr bis 14:00 Uhr entgegen.
            </>
        ),
    },
    {
        number: 10,
        color: 'dark',
        position: { left: '63.5%', top: '9.8%' },
        type: 'Orientierung in der Autostadt',
        title: 'Winterräder Pavillon',
        text: '',
    },
    {
        number: 11,
        color: 'blue',
        position: { left: '46.5%', top: '52%' },
        type: 'Orientierung in der Autostadt',
        title: 'KonzernWelt mit Ausstellungen',
        text: (
                <>
                    Das KonzernForum mit der großen Piazza ist der Ausgangspunkt für Ihren Besuch der Autostadt. Hier wird das Thema Mobilität markenübergreifend betrachtet. In der Ausstellung LEVEL GREEN schaffen Exponate anhand von Beispielen aus dem Alltag einen persönlichen Zugang zum Thema Nachhaltigkeit.
                    <br></br>
                    <br></br>
                    Auf 800 Quadratmetern erleben Sie im ID. Experience Studio Technik und Fahrzeuge der Volkswagen ID. Familie anhand von aktuellen ID. Modellen, interaktiven Exponaten und Videoinstallationen.
                    <br/>
                    <br/>
                    Ebenerdig erwartet Sie im MobiVersum unser Familienbereich mit Spielgelegenheiten und wechselnden Kreativangeboten für unsere jüngsten Gäste. Darüber hinaus gelangen Sie aus dem KonzernForum zu den Autostadt Restaurants. Auch die AbholerWelt – die erste Station für alle Fahrzeugabholer – betreten Sie von der Piazza aus.
                </>),
    },
    {
        number: 12,
        color: 'blue',
        position: { left: '30.7%', top: '51.3%' },
        type: 'Orientierung in der Autostadt',
        title: 'Museum ZeitHaus',
        text: (
            <>
                Schwelgen Sie bei einem Besuch des ZeitHauses in Automobilgeschichte. Die markenübergreifende Sammlung
                unseres Automobilmuseums beinhaltet mehr als 260 Fahrzeuge von über 60 verschiedenen Marken. Eine
                Schatzkammer voller Fahrzeuge, die Maßstäbe gesetzt haben und anderen als Vorbild dienten!
                <br />
                <br />
                An vielen Ausstellungspodesten der Fahrzeuge sind QR-Codes angebracht, die mit dem Smartphone gescannt
                werden können. Sie liefern spannende Videoclips, umfangreiche Daten sowie Hintergrundinformationen zu
                den Exponaten – viel Spaß beim Entdecken!
            </>
        ),
    },
    {
        number: 13,
        color: 'blue',
        position: { left: '26.2%', top: '46.2%' },
        type: 'Orientierung in der Autostadt',
        title: 'Pavillon z. Zt. wegen Umbau geschlossen',
        text: '',
    },
    {
        number: 14,
        color: 'blue',
        position: { left: '20.7%', top: '39%' },
        type: 'Orientierung in der Autostadt',
        title: 'The Ritz-Carlton, Wolfsburg',
        text: 'Ein Fünf-Sterne-Superior Hotel vor einzigartiger Industriekulisse: The Ritz-Carlton, Wolfsburg liegt direkt am Hafenbecken auf dem Autostadt Gelände und bietet Ihnen einen Ausblick auf die vier Schornsteine des denkmalgeschützten Volkswagen Kraftwerks. Der ringförmige Bau aus Glas und Naturstein bietet 147 luxuriöse Zimmer und 23 Suiten. Kunstliebhaber*innen werden sich über die 600 Werke international bekannter Künstler*innen im Inneren des Hotels freuen. Für Entspannung pur sorgt der außergewöhnliche Spabereich mit schwimmendem Außenpool. Eine Bar, Lounges und mehrere Restaurants vervollständigen das Angebot.',
    },
    {
        number: 15,
        color: 'blue',
        position: { left: '31.1%', top: '37.8%' },
        type: 'Orientierung in der Autostadt',
        title: 'Pavillon z. Zt. wegen Umbau geschlossen',
        text: '',
    },
    {
        number: 16,
        color: 'blue',
        position: { left: '40%', top: '39.1%' },
        type: 'Orientierung in der Autostadt',
        title: 'Škoda',
        text: 'Wie der geflügelte Pfeil des seit 1926 genutzten Škoda Markenlogos erscheint der Škoda Pavillon aus der Vogelperspektive und verbindet mit seiner Architektur Elemente, die aus verschiedenen Epochen der böhmischen Kulturgeschichte stammen. In der abwechslungsreichen Ausstellung haben Sie die Chance, die Marke Škoda auf ganz besondere Weise kennenzulernen und zu Entdeckerinnen und Entdeckern zu werden.',
    },
    {
        number: 17,
        color: 'blue',
        position: { left: '36.7%', top: '31%' },
        type: 'Orientierung in der Autostadt',
        title: 'Audi',
        text: 'Hochwertig, inspirierend und selbstbewusst präsentiert sich Audi im ersten dauerhaften Audi House of Progress bei uns in der Autostadt. An diesem einmaligen Erlebnisort kommt ein direkter Kontakt zwischen Menschen und Marke zustande. Erkunden Sie im neu gedachten und neu gestalteten Pavillon innovative und wegweisende Konzepte für eine nachhaltige Zukunft der Premiummobilität. Klare Linien und offene Raumstrukturen sorgen für ein zeitloses und einladendes Design. Treten Sie ein und erleben Sie die Schwerpunktthemen der Marke – Design, Digitalisierung, Performance und Nachhaltigkeit – anhand von Fahrzeugen, Filmen, Installationen und spielerischen Animationen, die die heutigen und künftigen Möglichkeiten von Technologien und Materialien inszenieren. Dabei fühlen Sie, was Vorsprung für Audi bedeutet und erhalten einen Einblick in die Innovationen.',
    },
    {
        number: 18,
        color: 'blue',
        position: { left: '49.5%', top: '33%' },
        type: 'Orientierung in der Autostadt',
        title: 'SEAT',
        text: 'Über eine Holzbrücke führt der Weg mitten hinein in den SEAT Pavillon, der sich die Geographie der iberischen Halbinsel zum Vorbild nimmt. Die an der Fassade angebrachten 349 Außenspiegel bilden einen farbenfrohen Kontrast zum Weiß des MarkenPavillons.',
    },
    {
        number: 19,
        color: 'blue',
        position: { left: '50.4%', top: '21.2%' },
        type: 'Orientierung in der Autostadt',
        title: 'Volkswagen',
        text: 'Anhand des Markenlogos an der Außenfassade erkennen Sie den Volkswagen Pavillon bereits von außen. Innen prägen spannende Beleuchtungselemente, Farbigkeit und eine natürliche Bildsprache das New Brand Design.',
    },
    {
        number: 20,
        color: 'blue',
        position: { left: '52.3%', top: '7.5%' },
        type: 'Orientierung in der Autostadt',
        title: 'Turmfahrt',
        text: '',
    },
    {
        number: 21,
        color: 'blue',
        position: { left: '74.5%', top: '28%' },
        type: 'Orientierung in der Autostadt',
        title: 'Volkswagen Nutzfahrzeuge',
        text: 'Kraftvoll, robust und funktional - so präsentieren sich die Volkswagen Nutzfahrzeuge. Und auch der MarkenPavillon, der die Parklandschaft der Autostadt nach Südosten hin abschließt, verkörpert mit seiner Form genau diese Eigenschaften. Im Inneren sehen Sie aktuelle Fahrzeuge, Studien und digitale Exponate, insbesondere zu den Themen Zukunft sowie Freiheit. Außerdem erwarten Sie viele Attraktionen zum Erleben und Mitmachen.',
    },
    {
        number: 22,
        color: 'blue',
        position: { left: '64%', top: '30.7%' },
        type: 'Orientierung in der Autostadt',
        title: 'Porsche',
        text: 'Eingebettet in die Park- und Lagunenlandschaft in direkter Nähe des Volkswagen Pavillons bringt der Porsche Pavillon die historische Verbundenheit von Volkswagen und Porsche zum Ausdruck. In einer einzigartigen Kombination aus Funktionalität und Design erleben Sie hier die Philosophie und Werte der traditionsreichen Sportwagenmarke. Von außen erkennen Sie den Pavillon auf den ersten Blick an seiner geschwungenen, matt schimmernden Dachkonstruktion, die weit über die Wasserfläche der Lagune ragt. Sowohl die Oberfläche aus Edelstahl als auch die dynamische und elegante Linienführung erinnern unverkennbar an Automobile aus dem Hause Porsche.',
    },
    {
        number: 23,
        color: 'blue',
        position: { left: '68.3%', top: '40%' },
        type: 'Orientierung in der Autostadt',
        title: 'ServiceHaus',
        text: '',
    },
    {
        number: 24,
        color: 'blue',
        position: { left: '53.3%', top: '64.2%' },
        type: 'Orientierung in der Autostadt',
        title: 'Gelände\u00ADParcours',
        text: 'Offroad-Qualität pur! Erfahrene Instruktoren zeigen Ihnen unsere GeländeParcours und machen Sie mit den technischen Möglichkeiten moderner Offroad-Fahrzeuge des Volkswagen Konzerns vertraut. Dann sind Sie an der Reihe, das anspruchsvolle Terrain zu erkunden: Auf den beiden GeländeParcours stellen Hindernisse wie Wassergraben, Treppenstufen oder eine überdimensionale Wippe das fahrerische Geschick auf die Probe und garantieren Fahrspaß pur.',
    },
    {
        number: 25,
        color: 'blue',
        position: { left: '92.5%', top: '57.3%', width: '5%', aspectRatio: '2' },
        type: 'Orientierung in der Autostadt',
        title: 'SicherheitsParcours',
        titleOverlay: 'Sicherheits\u00ADParcours',
        text: 'Der SicherheitsParcours verbindet eindrucksvolle Fahrerlebnisse mit Übungen zum sicheren Autofahren – auf nasser Fahrbahn oder am glatten Berg. Die Instruktor*innen zeigen Ihnen, wie Sie Ihr Fahrzeug auch in unerwarteten Situationen und bei schwieriger Wetterlage souverän steuern.',
    },
    {
        number: 26,
        color: 'blue',
        position: { left: '72.7%', top: '44%' },
        type: 'Orientierung in der Autostadt',
        title: 'Driving Experiences: Assistenzsysteme und Elektromobilität',
        text: '',
    },
    {
        number: 27,
        color: 'blue',
        position: { left: '70%', top: '4%' },
        type: 'Orientierung in der Autostadt',
        title: 'Parkplatz',
        text: '',
    },
    {
        number: 28,
        color: 'blue',
        position: { left: '86.4%', top: '4%' },
        type: 'Orientierung in der Autostadt',
        title: 'Parkplatz',
        text: '',
    },
    {
        number: 29,
        color: 'blue',
        position: { left: '92.5%', top: '19%' },
        type: 'Orientierung in der Autostadt',
        title: 'Parkplatz',
        text: '',
    },
    {
        number: 30,
        color: 'blue',
        position: { left: '89.3%', top: '30.4%' },
        type: 'Orientierung in der Autostadt',
        title: 'Parkplatz',
        text: '',
    },
    {
        number: 31,
        color: 'blue',
        position: { left: '79.5%', top: '35.5%' },
        type: 'Orientierung in der Autostadt',
        title: 'Behinderten\u00ADparkplatz',
        text: '',
    },
    {
        number: 32,
        color: 'blue',
        position: { left: '86.1%', top: '40%' },
        type: 'Orientierung in der Autostadt',
        title: 'Mondo Italiano',
        text: (
            <>
                Ein Cappuccino am Morgen, italienische Sandwiches zum Mittag oder einen erfrischenden Cocktail bei
                Loungemusik am Abend? Das alles gibt es im MONDO ITALIANO. Zelebrieren Sie gemeinsam mit uns die
                italienische Kaffee- und Essens-Kultur in unserem neuen Genusstreffpunkt direkt am Mittellandkanal. In
                der aufregenden Mischung aus Café, Lounge und Bar servieren wir Ihnen zu fünf Tageszeiten köstliches
                Fingerfood und exzellente Drinks im Schatten der Weiden mit Blick über das Wasser.
                <br />
                <br />
                Mit insgesamt 90 Sitzplätzen bietet das einem Bootshaus nachempfundene Restaurant zu jeder Tageszeit
                reichlich Platz, um Italien kulinarisch zu genießen.
            </>
        ),
    },
    {
        number: 33,
        color: 'blue',
        position: { left: '76.4%', top: '37.3%' },
        type: 'Orientierung in der Autostadt',
        title: 'E-Ladestationen',
        text: '',
    },
    {
        number: 34,
        color: 'blue',
        position: { left: '83.4%', top: '12.5%' },
        type: 'Orientierung in der Autostadt',
        title: 'E-Ladestationen',
        text: '',
    },
    {
        number: 35,
        color: 'blue',
        position: { left: '77.5%', top: '17%', width: '4%', aspectRatio: '1.7' },
        type: 'Orientierung in der Autostadt',
        title: 'Die schönsten Foto-Spots',
        text: (
            <>
                <b>Ich mit meinem Neuwagen:</b>
                <br />
                In der Ausfahrt
            </>
        ),
    },
    {
        number: 36,
        color: 'blue',
        position: { left: '65.8%', top: '23.8%', width: '4%', aspectRatio: '1.7' },
        type: 'Orientierung in der Autostadt',
        title: 'Die schönsten Foto-Spots',
        text: (
            <>
                <b>Mein Name auf der Anzeigentafel:</b>
                <br />
                Vor der Anzeigetafel im KundenCenter
            </>
        ),
    },
    {
        number: 37,
        color: 'blue',
        position: { left: '54.2%', top: '33.3%', width: '4%', aspectRatio: '1.7' },
        type: 'Orientierung in der Autostadt',
        title: 'Die schönsten Foto-Spots',
        text: (
            <>
                <b>Mein Auto im Turm:</b>
                <br />
                Auf der Kollerachse mit den Autotürmen im Hintergrund.
            </>
        ),
    },
    {
        number: 38,
        color: 'blue',
        position: { left: '21.6%', top: '57%', width: '4%', aspectRatio: '1.7' },
        type: 'Orientierung in der Autostadt',
        title: 'Die schönsten Foto-Spots',
        text: (
            <>
                <b>Geburtsstätte meines Neuwagens:</b>
                <br />
                Neben dem ZeitHaus mit Blick hinüber zum Kraftwerk.
            </>
        ),
    },
    {
        number: 39,
        color: 'blue',
        position: { left: '28.3%', top: '60%' },
        type: 'Orientierung in der Autostadt',
        title: 'Spielplatz',
        text: '',
    },
    {
        number: 40,
        color: 'blue',
        position: { left: '44.3%', top: '54%' },
        type: 'Orientierung in der Autostadt',
        title: 'Spielplatz',
        text: '',
    },
    {
        number: 41,
        color: 'blue',
        position: { left: '60.7%', top: '30%' },
        type: 'Orientierung in der Autostadt',
        title: 'Spielplatz',
        text: '',
    },
    {
        number: 43,
        color: 'blue',
        position: { left: '59.3%', top: '45.3%' },
        type: 'Orientierung in der Autostadt',
        title: 'Lagune',
        text: (
            <>
                Willkommen in unserem bio-zertifizierten Marktrestaurant. In geselliger Atmosphäre finden Sie hier eine
                große Auswahl frischer Köstlichkeiten, davon das meiste in Bio-Qualität und aus der Region. An
                verschiedenen Stationen wählen Sie Ihre warmen und kalten Favoriten und kreieren so Ihr perfektes
                persönliches Gericht. Während Sie gemütlich im Restaurant oder auf der Außenterrasse Platz nehmen,
                können Sie entspannt den Blick über die Park- und Lagunenlandschaft schweifen lassen.
                <br />
                <br />
                Im Eingangsbereich des Restaurants erhalten Sie eine Magnetkarte. Gezahlt wird erst beim Verlassen des
                Restaurants am Ausgang.
            </>
        ),
    },
    {
        number: 44,
        color: 'blue',
        position: { left: '53.2%', top: '50%' },
        type: 'Orientierung in der Autostadt',
        title: 'Garderobe & Schließfächer',
        text: '',
    },
    {
        number: 45,
        color: 'blue',
        position: { left: '35.7%', top: '51%' },
        type: 'Orientierung in der Autostadt',
        title: 'Moment! Marken & Erlebnis-Shop',
        text: 'Auf mehr als 350 Quadratmetern können Sie im Erdgeschoss des ZeitHauses drei spannende Shoppingwelten unter einem Dach erleben: Zahlreiche Marken des Volkswagen Konzerns präsentieren sich in den drei Themenbereichen „Volkswagen Markenwelt“, „Innovation“ und „Lifestyle“. Entdecken Sie den einzigartigen Mix aus handverlesenen, neuen und bewährten Produkten! Der Weg durch unsere Automobilausstellung führt Sie automatisch ins neue Einkaufsparadies.',
    },
    {
        number: 46,
        color: 'blue',
        position: { left: '33.3%', top: '51%' },
        type: 'Orientierung in der Autostadt',
        title: 'Erste Sahne',
        text: 'In unserem Café „Erste Sahne” erwarten Sie Kaffeetrends aus aller Welt, Bohnen aus hauseigener Röstung, herzhafte Snacks und köstliche Kuchen. Das Erste Sahne ist ein „place to be” für Kaffeeliebhaber und Lifestyle-Freunde. Gönnen Sie sich eine Auszeit im gemütlichen Ambiente unsere liebevoll eingerichteten Cafés.',
    },
    {
        number: 47,
        color: 'blue',
        position: { left: '65%', top: '17.7%' },
        type: 'Orientierung in der Autostadt',
        title: 'Tachometer',
        text: (
            <>
                Das Tachometer ist der ideale Ort für eine Stärkung vor dem aufregenden Moment der Fahrzeugübernahme.
                Hier sind Sie Ihrem Neuwagen bereits ganz nah. In unserem Selbstbedienungsrestaurant mit Rundumblick
                bieten wir Ihnen aktuell belegte Brötchen, eine köstliche Kuchenauswahl, Kalt- sowie Heißgetränke und
                Kultprodukte wie den VW Original Gewürz Ketchup als kulinarisches Andenken für zu Hause.
                <br />
                <br />
                Hinweis: Das Kultprodukt aus Wolfsburg, die Volkswagen Currywurst, servieren wir derzeit in unserem
                Restaurant Lagune im KonzernForum.
            </>
        ),
    },
    {
        number: 48,
        color: 'blue',
        position: { left: '67.4%', top: '17.7%' },
        type: 'Orientierung in der Autostadt',
        title: 'Volkswagen Zubehör Shop',
        text: 'Ob Buggy, Bekleidung oder Bulli-Modell – dieser Shop bietet exklusives Volkswagen Zubehör aller Art im KundenCenter an. Auf 270 Quadratmetern finden Sie rund 800 Produkte rund um das Thema Volkswagen, natürlich auch die passenden Accessoires für Ihr Fahrzeug – vom Warndreieck bis zum Navigationsgerät.',
    },
    {
        number: 49,
        color: 'blue',
        position: { left: '50.8%', top: '49.8%' },
        type: 'Orientierung in der Autostadt',
        title: 'Amano',
        text: 'In unserem bio-zertifizierten Familienrestaurant ist der Name Programm: Die handgemachte Pizza aus dem Steinofen schmeckt hier wie aus Italien. Malspaß gibt es für Kinder inklusive: Auf der „Mal dir deine Wunschpizza“- Vorlage gestalten sie ihre Lieblingspizza mit bis zu vier Wunschzutaten selbst und geben damit ihre Bestellung auf. Der Clou: Kinder werden bei uns zum Pizzabäcker und belegen ihre Pizza selbst.',
    },
    {
        number: 50,
        color: 'blue',
        position: { left: '68%', top: '45.7%' },
        type: 'Restaurants, Shops & Highlights',
        title: 'Das Brot.',
        text:
            'Genießen Sie ofenwarme Brote und Backwaren aus unserer eigenen Manufaktur. Durch die gläserne' +
            ' Scheibe gewinnen Sie bereits von außen einen Einblick in unser traditionelles Backhandwerk. In der Backstube stellen wir täglich frisch Brot, Brötchen und Backwaren aus regionalen, biologischen Zutaten und verschiedenen Kornsorten für Sie her.',
    },
    {
        number: 51,
        color: 'blue',
        position: { left: '23.5%', top: '39%' },
        type: 'Restaurants, Shops & Highlights',
        title: 'Restaurants im Ritz-Carlton',
        text: (
            <>
                <h3>AQUA</h3>
                <br />
                Der Name des Gourmet- Restaurants Aqua ist bei den preisgekrönten Gerichten von Sven Elverfeld Programm
                und symbolisiert Klarheit und Transparenz. Das Aqua öffnet seine Türen abends und bietet modernste
                europäische Küche.
                <br />
                <br />
                <h3>TERRA</h3>
                <br />
                Der Name des Gourmet- Restaurants Aqua ist bei den preisgekrönten Gerichten von Sven Elverfeld Programm
                und symbolisiert Klarheit und Transparenz. Das Aqua öffnet seine Türen abends und bietet modernste
                europäische Küche.
                <br />
                <br />
                <h3>NEWMAN’S BAR</h3>
                <br />
                Die Newman’s Bar ist mehr als eine klassische American Bar. Individuell zugeschnittene Cocktails und
                Longdrinks sind die Besonderheit in dieser Bar. Das Team berät Sie bei Ihrer Getränkeauswahl und geht
                auf Ihre individuellen Wünsche ein.
            </>
        ),
    },
    {
        number: 52,
        color: 'blue',
        position: { left: '37%', top: '58%' },
        type: 'Orientierung in der Autostadt',
        title: 'Spielplatz',
        text: '',
    },
];

export const mapPointsTravel: MapPoint[] = [
    {
        number: 1,
        color: 'green',
        position: { left: '73.5%', top: '66%', width: '5%' },
        type: 'Restaurants, Shops & Highlights',
        title: 'Gepäckabgabe',
        text: 'Möglichkeit zur Gepäckabgabe innerhalb der Autostadt.',
    },
];
