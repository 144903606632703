import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import React, { useEffect, useRef, useState } from 'react';

export function ImageViewer(props: {
    images: Array<SSPContentVO>;
    imageIndex?: number;
    setImageIndexEventType?: string;
}) {
    const ref = useRef<ReactZoomPanPinchRef | null>(null);
    const [index, setIndex] = useState(props.imageIndex || 0);
    const size = {
        height: props.images[index].asAsset.fileProperties.fileMeta.integer('Height'),
        width: props.images[index].asAsset.fileProperties.fileMeta.integer('Width'),
    };
    const scale = Math.min(window.innerWidth, window.innerHeight * 0.9) / size.width;

    useEffect(() => {
        ref.current?.resetTransform();
        if (props.setImageIndexEventType)
            window.dispatchEvent(new CustomEvent(props.setImageIndexEventType, { detail: { index: index } }));
    }, [index]);

    window.addEventListener('resize', () => {
        ref.current?.resetTransform();
    });

    return (
        <TransformWrapper centerOnInit={true} initialScale={scale} minScale={scale} maxScale={scale * 5} ref={ref}>
            <TransformComponent
                wrapperStyle={{ height: '100%', width: '100%', backgroundColor: '#DFE4E8' }}
                contentStyle={size}
            >
                <img src={props.images[index].asAsset.url} alt={props.images[index].name} />
            </TransformComponent>
            <button
                className='gallery-switch-button left'
                disabled={index === 0}
                onClick={() => {
                    setIndex(Math.max(0, index - 1));
                }}
            >
                <svg>
                    <use xlinkHref='#chevron-narrow-right' />
                </svg>
            </button>
            <button
                className='gallery-switch-button right'
                disabled={props.images.length === 0 || index === props.images.length - 1}
                onClick={() => {
                    setIndex(Math.min(props.images.length - 1, index + 1));
                }}
            >
                <svg>
                    <use xlinkHref='#chevron-narrow-right' />
                </svg>
            </button>
        </TransformWrapper>
    );
}
