import React, { useContext, useState } from 'react';
import CustomLink from '../CustomLink';
import { MenuProps } from './interfaces';
import './styles.scss';
import SspProviderContext from '../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { getAssistants } from '../Expose/utils/getAssistants';
import { getHighlights } from '../Expose/utils/getHighlights';

export default function Menu(props: MenuProps) {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const engineType = contentModel.ref('engine').asModel.str('engine_type');

    const [subList, setSubList] = useState<number>(0);

    return (
        <div className={subList === 1 || subList === 2 ? 'menu white-background' : 'menu'}>
            <div className={`menu-blue-background ${subList === 1 || subList === 2 ? 'sub-list' : ''}`} />
            <div className='text-area'>
                <ul className='main-list'>
                    {(subList === 0 || subList === 1) && (
                        <>
                            <li onClick={() => setSubList(1)}>
                                <div className={subList === 1 ? 'main-link-active' : 'main-link'}>
                                    {subList !== 1 && (
                                        <svg className='menu-icon' style={{ fill: 'white', stroke: 'none' }}>
                                            <use xlinkHref='#car' />
                                        </svg>
                                    )}
                                    <CustomLink to='/#overview'>
                                        <span
                                            className={subList === 1 ? 'main-link-active bold' : 'bold'}
                                            onClick={() => {
                                                props.setMenuOpen(false);
                                            }}
                                        >
                                            Mein Fahrzeug
                                        </span>
                                    </CustomLink>
                                    {subList !== 1 && (
                                        <svg className='chevron-icon' style={{ fill: 'none', stroke: 'white' }}>
                                            <use xlinkHref='#nav-chevron' />
                                        </svg>
                                    )}
                                </div>
                            </li>
                            {subList === 1 && (
                                <li className='sub-list'>
                                    <ul>
                                        <CustomLink to='/#overview-text'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Übersicht</span>
                                            </li>
                                        </CustomLink>
                                        {getHighlights(contentModel).length > 0 && (
                                            <CustomLink to='/#highlights'>
                                                <li
                                                    onClick={() => {
                                                        props.setMenuOpen(false);
                                                    }}
                                                >
                                                    <span>Ausstattungshighlights</span>
                                                </li>
                                            </CustomLink>
                                        )}
                                        <CustomLink to='/#gallery'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Galerie</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/#details'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Fahrzeugdetails</span>
                                            </li>
                                        </CustomLink>
                                        {getAssistants(contentModel).length > 0 && (
                                            <CustomLink to='/#assistants'>
                                                <li
                                                    onClick={() => {
                                                        props.setMenuOpen(false);
                                                    }}
                                                >
                                                    <span>Assistenzsysteme</span>
                                                </li>
                                            </CustomLink>
                                        )}
                                        <CustomLink to='/#media-center'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Meine Mediathek</span>
                                            </li>
                                        </CustomLink>
                                        {engineType === 'Electric' && (
                                            <>
                                                <CustomLink to='/#we-connect'>
                                                    <li
                                                        onClick={() => {
                                                            props.setMenuOpen(false);
                                                        }}
                                                    >
                                                        <span>Konnektivität</span>
                                                    </li>
                                                </CustomLink>
                                                <CustomLink to='/#e-mobility'>
                                                    <li
                                                        onClick={() => {
                                                            props.setMenuOpen(false);
                                                        }}
                                                    >
                                                        <span>Elektromobilität</span>
                                                    </li>
                                                </CustomLink>
                                                <CustomLink to='/#further-links'>
                                                    <li
                                                        onClick={() => {
                                                            props.setMenuOpen(false);
                                                        }}
                                                    >
                                                        <span>Top-Links</span>
                                                    </li>
                                                </CustomLink>
                                            </>
                                        )}
                                    </ul>
                                </li>
                            )}
                        </>
                    )}
                    {(subList === 0 || subList === 2) && (
                        <>
                            <li onClick={() => setSubList(2)}>
                                <div className={subList === 2 ? 'main-link-active' : 'main-link'}>
                                    {subList !== 2 && (
                                        <svg className='menu-icon'>
                                            <use xlinkHref='#home' />
                                        </svg>
                                    )}
                                    <span className={subList === 2 ? 'main-link-active bold' : 'bold'}>
                                        Meine Erlebnisabholung
                                    </span>
                                    {subList !== 2 && (
                                        <svg className='chevron-icon' style={{ fill: 'none', stroke: 'white' }}>
                                            <use xlinkHref='#nav-chevron' />
                                        </svg>
                                    )}
                                </div>
                            </li>
                            {subList === 2 && (
                                <li className='sub-list'>
                                    <ul>
                                        <CustomLink to='/pick-up#intro'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Willkommen</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#checklist'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Checkliste</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#checkin'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Online Check-in</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#plan'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Anreise</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#luggage'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Gepäckabgabe</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#pick-up'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Fahrzeugabholung</span>
                                            </li>
                                        </CustomLink>
                                        <CustomLink to='/pick-up#contact'>
                                            <li
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <span>Kontakt</span>
                                            </li>
                                        </CustomLink>
                                    </ul>
                                </li>
                            )}
                            {subList === 0 && (
                                <>
                                    <li className='last'>
                                        <CustomLink to='/map'>
                                            <div
                                                className='main-link'
                                                onClick={() => {
                                                    props.setMenuOpen(false);
                                                }}
                                            >
                                                <svg className='menu-icon'>
                                                    <use xlinkHref='#map' />
                                                </svg>
                                                <span className='bold'>Übersichtsplan </span>
                                            </div>
                                        </CustomLink>
                                    </li>
                                </>
                            )}
                        </>
                    )}
                </ul>
                {subList === 0 && (
                    <div className='further'>
                        <ul className='further-links'>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a rel='noreferrer' target='_blank' href='https://www.autostadt.de'>
                                        Autostadt
                                    </a>
                                </span>
                            </li>
                            <CustomLink to={'/opening'}>
                                <li>
                                    <div className='blue-marker' />
                                    <span>Öffnungszeiten</span>
                                </li>
                            </CustomLink>
                            <CustomLink to='/contact'>
                                <li
                                    onClick={() => {
                                        props.setMenuOpen(false);
                                    }}
                                >
                                    <div className='blue-marker' />
                                    <span>Kontakt</span>
                                </li>
                            </CustomLink>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://www.autostadt.de/service-kontakt/faq'
                                    >
                                        FAQ
                                    </a>
                                </span>
                            </li>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://www.autostadt.de/service-kontakt/newsletter'
                                    >
                                        Newsletter
                                    </a>
                                </span>
                            </li>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://autostadt.survalyzer.eu/nrrgodwpvh?l=de'
                                    >
                                        Feedback
                                    </a>
                                </span>
                            </li>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://www.autostadt.de/footer/rechtliches/datenschutzerklaerung'
                                    >
                                        Datenschutzerklärung
                                    </a>
                                </span>
                            </li>
                            <li>
                                <div className='blue-marker' />
                                <span>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href='https://www.autostadt.de/footer/impressum'
                                    >
                                        Impressum
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <div className='menu-close-container'>
                <button
                    className={`close-button-menu ${subList !== 0 ? ' close-sub-list' : ''}`}
                    onClick={() => {
                        props.setMenuOpen(false);
                    }}
                >
                    <svg>
                        <use xlinkHref='#close' />
                    </svg>
                </button>
                {subList === 0 && (
                    <div className='goback-text' onClick={() => props.setMenuOpen(false)}>
                        Menü schließen
                    </div>
                )}
            </div>

            {/* Blue-Bar */}
            {/*<div className={subList !== 0 ? "blue-bar opened" : "blue-bar"}>*/}
            {/*    /!* <button className="close-button" onClick={() => setSubList(0)}> *!/*/}
            {/*</div>*/}

            {(subList === 1 || subList == 2) && (
                <div className='goback-text-sublist' onClick={() => setSubList(0)}>
                    <svg>
                        <use xlinkHref='#back-arrow-link' />
                    </svg>
                    <span>Zurück</span>
                </div>
            )}
        </div>
    );
}
