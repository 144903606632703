import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import PickUp from './components/PickUp';
import Map from './components/Map';
import Expose from './components/Expose';
import { ICON_HTML } from './components/Icons';
import Checkin from './components/CheckIn';
import SspContextProvider from './utils/SspProvider/SspContextProvider';
import IFrame from './components/IFrame';
import Closeable from './components/Closeable';
import { CloseableEnum } from './enums';

function App() {
    return (
        <SspContextProvider>
            <div className='App'>
                <div className='svgtemplates' dangerouslySetInnerHTML={{ __html: ICON_HTML }} />
                <HashRouter>
                    <Routes>
                        <Route path='/pick-up' element={<PickUp />} />
                        <Route path='/map' element={<Map travel={false} />} />
                        <Route path='/travel-map' element={<Map travel={true} />} />
                        <Route path='/checkin' element={<Checkin />} />
                        <Route path='/opening' element={<Closeable content={CloseableEnum.Opening} />} />
                        <Route path='/contact' element={<Closeable content={CloseableEnum.Contact} />} />
                        <Route path='/iframe/:url' element={<IFrame />} />
                        <Route path='/' element={<Expose />} />
                        <Route path='/error' element={<h1>Page not found</h1>} />
                    </Routes>
                </HashRouter>
            </div>
        </SspContextProvider>
    );
}

export default App;
