import React, { useContext, useRef, useState } from 'react';
import { CarDetailEnum } from '../../../enums';
import SspProviderContext from '../../../utils/SspProvider/context';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
// import TechnicalData from "./TechnicalData";
import { OverlayType } from '../../Overlay/interfaces';

export default function Details(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    // const vehicleData = contentModel.ref('vehicle_data').asModel;
    // const propulsionType = contentModel.ref('vehicle_data').contentType;
    const engine = contentModel.ref('engine').asModel;
    const standardEquipList = contentModel.reflist('serie_equip');
    const optionalEquipList = contentModel.reflist('special_equip');

    const standardCategories = [
        ...new Set(standardEquipList.map(standardEquipItem => standardEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const optionalCategories = [
        ...new Set(optionalEquipList.map(optionalEquipItem => optionalEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const [currentDetails, setCurrentDetails] = useState<CarDetailEnum>(CarDetailEnum.Overview);
    const [currentStandardCategory, setCurrentStandardCategory] = useState<number>(-1);
    const [currentOptionalCategory, setCurrentOptionalCategory] = useState<number>(-1);
    const [shadowLeft, setShadowLeft] = useState<boolean>(true);
    const [shadowRight, setShadowRight] = useState<boolean>(false);

    const detailHeaderRef = useRef<HTMLDivElement>(null);

    // const consumptionUnit = propulsionType === 'VehicleDataElectric' ? ' kWh/100km' : propulsionType === 'VehicleDataGas' ? ' kg/100km' : ' l/100km';

    function handleDetails(detail: CarDetailEnum): void {
        const headerItem = document.getElementById(`detail-header-item-${detail}`);
        if (detailHeaderRef.current && headerItem) {
            const headerItemPosition = headerItem.offsetLeft;
            detailHeaderRef.current.scrollTo({ left: headerItemPosition, behavior: 'smooth' });
        }

        setCurrentDetails(detail);
    }

    function handleScroll(): void {
        if (detailHeaderRef.current) {
            const scrollRight =
                detailHeaderRef.current.children[1].getBoundingClientRect().width -
                detailHeaderRef.current.scrollLeft -
                detailHeaderRef.current.getBoundingClientRect().width;
            setShadowRight(scrollRight < 1);

            const activeItem = document.getElementById(`detail-header-item-${currentDetails}`);
            setShadowLeft(
                detailHeaderRef.current.scrollLeft < 1 ||
                    (activeItem !== null && Math.abs(activeItem.offsetLeft - detailHeaderRef.current.scrollLeft) < 1)
            );
        }
    }

    function getOverlay(equipItem: SSPContentVO, title: string): OverlayType {
        const hasAsset = equipItem.asModel.has('assets');
        let disclaimers: Array<string> = [];

        if (equipItem.asModel.has('disclaimers')) {
            disclaimers = equipItem.asModel.objlist('disclaimers').map((keyValue: any) => keyValue.Value);
        }

        if (equipItem.asModel.has('packageItems')) {
            equipItem.asModel.reflist('packageItems').forEach(packageItem => {
                disclaimers = [
                    ...new Set([
                        ...disclaimers,
                        ...packageItem.asModel.objlist('disclaimers').map((keyValue: any) => keyValue.Value),
                    ]),
                ];
            });
        }
        return {
            title: title,
            baseContent: equipItem,
            packageContents: equipItem.asModel.reflist('packageItems'),
            disclaimerList: disclaimers,
            isVideo: hasAsset && equipItem.asModel.reflist('assets')[0].asAsset.fileProperties.fileType === 'mp4',
            headline: equipItem.asModel.str('layertitle'),
            description: equipItem.asModel.str('description') ? equipItem.asModel.str('description') : '',
            media: hasAsset ? equipItem.asModel.reflist('assets')[0].asAsset.url : undefined,
        };
    }

    return (
        <div id='details'>
            <h1>Fahrzeugdetails</h1>
            <div className='detail-area'>
                <div className='detail-header-area' ref={detailHeaderRef} onScroll={handleScroll}>
                    <div className={`detail-header-gradient left${shadowLeft ? ' hide' : ''}`} />
                    <div className='detail-header'>
                        <div
                            id={`detail-header-item-${CarDetailEnum.Overview}`}
                            className={`detail-header-item${
                                currentDetails === CarDetailEnum.Overview ? ' active-item' : ''
                            }`}
                            onClick={() => {
                                handleDetails(CarDetailEnum.Overview);
                            }}
                        >
                            Übersicht
                        </div>
                        {/*<div*/}
                        {/*    id={`detail-header-item-${CarDetailEnum.TechData}`}*/}
                        {/*    className={*/}
                        {/*        `detail-header-item${currentDetails === CarDetailEnum.TechData ? ' active-item' : ''}`}*/}
                        {/*    onClick={() => {*/}
                        {/*        handleDetails(CarDetailEnum.TechData)*/}
                        {/*    }}*/}
                        {/*>Technische Daten*/}
                        {/*</div>*/}
                        <div
                            id={`detail-header-item-${CarDetailEnum.Standard}`}
                            className={`detail-header-item${
                                currentDetails === CarDetailEnum.Standard ? ' active-item' : ''
                            }`}
                            onClick={() => {
                                handleDetails(CarDetailEnum.Standard);
                            }}
                        >
                            Serienausstattung
                        </div>
                        {optionalEquipList.length > 0 && (
                            <div
                                id={`detail-header-item-${CarDetailEnum.Optional}`}
                                className={`detail-header-item${
                                    currentDetails === CarDetailEnum.Optional ? ' active-item' : ''
                                }`}
                                onClick={() => {
                                    handleDetails(CarDetailEnum.Optional);
                                }}
                            >
                                Sonderausstattung
                            </div>
                        )}
                        <div className='detail-header-line' />
                    </div>
                    <div className={`detail-header-gradient right${shadowRight ? ' hide' : ''}`} />
                </div>
                <div className='detail-table-area'>
                    {currentDetails === CarDetailEnum.Overview && (
                        <table className='detail-table-overview'>
                            <tbody>
                                <tr>
                                    <td>
                                        <svg>
                                            <use xlinkHref='#power' />
                                        </svg>
                                        <div>
                                            <b>Leistung</b>
                                        </div>
                                        <p>
                                            {' '}
                                            {engine.has('fuel_power')
                                                ? `${engine.str('fuel_power')} KW`
                                                : engine.str('engine_type') === 'Electric'
                                                  ? `${engine.str('fuel_power2')} KW`
                                                  : '-'}
                                        </p>
                                    </td>
                                    <td>
                                        <svg>
                                            <use xlinkHref='#gear' />
                                        </svg>
                                        <div>
                                            <b>Getriebe</b>
                                        </div>
                                        {engine !== undefined && engine.str('motor_gear') !== undefined ? (
                                            <p>{engine.str('motor_gear')}</p>
                                        ) : (
                                            <p>--</p>
                                        )}
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*    <td>*/}
                                {/*        <svg>*/}
                                {/*            <use xlinkHref='#emission'/>*/}
                                {/*        </svg>*/}
                                {/*        <div>*/}
                                {/*            <b>Emission</b>*/}
                                {/*            <div className='in-circle'*/}
                                {/*                 onClick={() => setOverlay(store, {disclaimer: 1})}>1*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <p>{vehicleData.str('nedc_co2_combined') + ' g/km'}</p>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <svg>*/}
                                {/*            <use xlinkHref='#consumption'/>*/}
                                {/*        </svg>*/}
                                {/*        <div>*/}
                                {/*            <b>Verbrauch</b>*/}
                                {/*            <div className='in-circle'*/}
                                {/*                 onClick={() => setOverlay(store, {disclaimer: 1})}>1*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <p>{vehicleData.float('nedc_consumption_combined').toLocaleString('de-DE') +*/}
                                {/*            consumptionUnit}</p>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td>
                                        <svg>
                                            <use xlinkHref='#motor' />
                                        </svg>
                                        <div>
                                            <b>Antrieb</b>
                                        </div>
                                        <p>{engine.str('motor_drive')}</p>
                                    </td>
                                    {/*<td>*/}
                                    {/*    <svg>*/}
                                    {/*        <use xlinkHref='#efficiency'/>*/}
                                    {/*    </svg>*/}
                                    {/*    <div>*/}
                                    {/*        <b>Effizienzklasse</b>*/}
                                    {/*        <div className='in-circle'*/}
                                    {/*             onClick={() => setOverlay(store, {disclaimer: 2})}>2*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className='efficiency-class'>*/}
                                    {/*        <div*/}
                                    {/*            className='efficiency-class-arrow'>{vehicleData.str('nedc_energy_efficiency')}</div>*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    <td>
                                        <svg>
                                            <use xlinkHref='#fuel' />
                                        </svg>
                                        <div>
                                            <b>Kraftstoffart</b>
                                        </div>
                                        <p>
                                            {engine.str('engine_type') === 'Electric'
                                                ? engine.str('fuel2')
                                                : engine.str('fuel_type')}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {/*{currentDetails === CarDetailEnum.TechData && <TechnicalData/>}*/}
                    {currentDetails === CarDetailEnum.Standard && (
                        <table className='detail-table-standard'>
                            {standardCategories.map((category, index) => (
                                <React.Fragment key={`standard-categories-${index}`}>
                                    <tbody>
                                        <tr
                                            className={`headline ${
                                                currentStandardCategory === index ? 'open' : 'closed'
                                            }`}
                                            onClick={() => {
                                                setCurrentStandardCategory(
                                                    currentStandardCategory === index ? -1 : index
                                                );
                                            }}
                                        >
                                            <td>{category}</td>
                                            {true && (
                                                <td>
                                                    <button className='td-button'>
                                                        <svg>
                                                            <use xlinkHref='#chevron-narrow-right' />
                                                        </svg>
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                    {currentStandardCategory === index && (
                                        <tbody className='subs-container'>
                                            {standardEquipList
                                                .filter(
                                                    equipItem => equipItem.asModel.str('equipcategory') === category
                                                )
                                                .filter(cat => cat)
                                                .map(filteredEquipItem => (
                                                    <tr className='sub' key={filteredEquipItem.asModel.str('key')}>
                                                        <td>{filteredEquipItem.asModel.str('layertitle')}</td>
                                                        <td>
                                                            {(filteredEquipItem.asModel.has('assets') ||
                                                                filteredEquipItem.asModel.has('description') ||
                                                                filteredEquipItem.asModel.has('disclaimers')) && (
                                                                <svg
                                                                    onClick={() =>
                                                                        setOverlay(
                                                                            store,
                                                                            getOverlay(filteredEquipItem, category)
                                                                        )
                                                                    }
                                                                >
                                                                    <use xlinkHref='#equipment-info' />
                                                                </svg>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    )}
                                </React.Fragment>
                            ))}
                        </table>
                    )}
                    {currentDetails === CarDetailEnum.Optional && (
                        <table className='detail-table-optional'>
                            {optionalCategories.map((category, index) => (
                                <React.Fragment key={`optional-categories-${index}`}>
                                    <tbody>
                                        <tr
                                            className={`headline ${
                                                currentOptionalCategory === index ? 'open' : 'closed'
                                            }`}
                                            onClick={() => {
                                                setCurrentOptionalCategory(
                                                    currentOptionalCategory === index ? -1 : index
                                                );
                                            }}
                                        >
                                            <td>{category}</td>
                                            {true && (
                                                <td>
                                                    <button className='td-button'>
                                                        <svg>
                                                            <use xlinkHref='#chevron-narrow-right' />
                                                        </svg>
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                    {currentOptionalCategory === index && (
                                        <tbody className='subs-container'>
                                            {optionalEquipList
                                                .filter(
                                                    equipItem => equipItem.asModel.str('equipcategory') === category
                                                )
                                                .filter(cat => cat)
                                                .map(filteredEquipItem => (
                                                    <tr className='sub' key={filteredEquipItem.asModel.str('key')}>
                                                        <td>{filteredEquipItem.asModel.str('layertitle')}</td>
                                                        <td>
                                                            {filteredEquipItem.asModel.has('assets') && (
                                                                <svg
                                                                    onClick={() =>
                                                                        setOverlay(
                                                                            store,
                                                                            getOverlay(filteredEquipItem, category)
                                                                        )
                                                                    }
                                                                >
                                                                    <use xlinkHref='#equipment-info' />
                                                                </svg>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    )}
                                </React.Fragment>
                            ))}
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}
