import React from 'react';
import eMobilityImage from '../../../assets/images/Elektromobilitaet.jpg';
import weConnectImage from '../../../assets/images/WeCharge.jpg';
import mediaCenterImage from '../../../assets/images/Mediathek.jpg';

export default function InfoArea(props: { elementID: string }): JSX.Element {
    let data = null;
    if (props.elementID === 'media-center') {
        data = {
            img: mediaCenterImage,
            imgAlt: 'Bild zur Mediathek',
            headline: 'Meine Mediathek',
            text: 'Lernen Sie die neuen Volkswagen Modelle in spannenden Filmen kennen.',
            link: 'https://www.volkswagen.de/de/besitzer-und-nutzer/myvolkswagen/media-library.html',
            linkText: 'Filme anschauen',
        };
    }

    if (props.elementID === 'we-connect') {
        data = {
            img: weConnectImage,
            imgAlt: 'Bild zu WeConnect',
            headline: 'Konnektivität',
            text: 'Mit den Konnektivitätsangeboten von Volkswagen hält die Digitalisierung Einzug in Ihren Volkswagen und ermöglicht Ihnen mehr Komfort, Sicherheit und Unterhaltung. Durch die Verbindung von Ihrem Fahrzeug mit dem Internet erhalten Sie Echtzeitverkehrsinformationen, die Erinnerung an das nächste Service-Intervall, Streaming und Webradio oder schnelle Hilfe im Falle einer Panne.',
            link: 'https://www.volkswagen.de/de/konnektivitaet-und-mobilitaetsdienste/konnektivitaet/we-connect.html',
            linkText: 'Aktivierungsanleitung',
        };
    }

    if (props.elementID === 'e-mobility') {
        data = {
            img: eMobilityImage,
            imgAlt: 'Bild zur Elektromobilität',
            headline: 'Elektromobilität',
            text: 'Wo und wie Sie Ihr Elektrofahrzeug laden können, wie Sie am einfachsten bezahlen und wie groß die elektrische Reichweite ist – hier erfahren Sie alles, was wichtig ist.',
            link: 'https://www.volkswagen.de/de/elektrofahrzeuge/laden-und-reichweite.html',
            linkText: 'Laden und Reichweite',
        };
    }

    if (data)
        return (
            <div id={props.elementID}>
                <div className='img-area'>
                    <div className='img-container'>
                        <img src={data.img} alt={data.imgAlt} />
                    </div>
                </div>
                <div className='info-area-text'>
                    <h1>{data.headline}</h1>
                    <p>{data.text}</p>
                    <a href={data.link}>
                        <button className='info-area-btn'>{data.linkText}</button>
                    </a>
                </div>
            </div>
        );

    return <></>;
}
