import React, { useLayoutEffect, useState } from 'react';

export default function AssistantsMap(props: { assistants: any[] }): JSX.Element {
    const [overflow, setOverflow] = useState<Array<number>>([]);
    const [open, setOpen] = useState<Array<number>>([]);

    // check which assistant containers are overflowing and, therefore, need a button to open them
    useLayoutEffect(() => {
        const withoutTransition = document.getElementById('without-transition');
        const contents = withoutTransition?.getElementsByClassName('assistant-container-content');
        if (contents) {
            const selected = [];
            for (let index = 0; index < contents.length; index++) {
                if (contents[index].scrollHeight > 480) {
                    selected.push(index);
                }
            }
            setOverflow(selected);
        }
    }, []);

    // adds / removes index to / from list of open assistants
    function toggleAssistant(index: number) {
        let newList = open;
        if (open.includes(index)) {
            const openIndex = open.indexOf(index);
            if (openIndex > -1) {
                newList.splice(openIndex, 1);
            }
        } else {
            newList = [...open, index];
        }
        setOpen([...newList]);
    }

    return (
        <>
            {props.assistants.map((assistant: any, index: any) => (
                <div
                    className='assistant-container'
                    key={`assistant-${index}`}
                    style={!open.includes(index) && overflow.includes(index) ? { height: 480 } : { paddingBottom: 30 }}
                >
                    <div className='assistant-container-content'>
                        <h1>
                            {assistant.asModel.str('layertitle')}
                            {assistant.asModel.has('disclaimers') && (
                                <sup className='disclaimer-hint'>
                                    {assistant.asModel
                                        .objlist('disclaimers')
                                        .map((keyValue: any, index: number) => index + 1)
                                        .sort()
                                        .join(',')}
                                </sup>
                            )}
                        </h1>
                        {assistant.asModel.has('assets') && (
                            <img
                                src={assistant.asModel.reflist('assets')[0].asAsset.url}
                                alt={assistant.asModel.str('layertitle')}
                            />
                        )}
                        {assistant.asModel.has('description') && (
                            <p>
                                {assistant.asModel.str('description')}
                                {index === 0 && <span className='in-circle'>1</span>}
                            </p>
                        )}
                        {assistant.asModel.has('disclaimers') && (
                            <div className='equip-disclaimer'>
                                <table>
                                    <tbody>
                                        {assistant.asModel
                                            .objlist('disclaimers')
                                            .map((disclaimer: any, index: number) => (
                                                <tr key={'disclaimer-' + index}>
                                                    <td key={'disclaimer-index-' + index}>{index + 1}</td>
                                                    <td key={'disclaimer-text-' + index}>{disclaimer.Value}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    {!open.includes(index) && overflow.includes(index) && (
                        <div className='assistant-container-shadow' />
                    )}
                    {overflow.includes(index) && (
                        <button
                            className={`assistants-switch${!open.includes(index) ? '' : ' open'}`}
                            onClick={() => toggleAssistant(index)}
                        >
                            <svg>
                                <use xlinkHref='#assistants-button-arrow' />
                            </svg>
                        </button>
                    )}
                </div>
            ))}
        </>
    );
}
