import React, { useContext, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Indicators from '../../Indicators';
import SspProviderContext from '../../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import Panoramara from './Panoramara';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';

export default function Gallery(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;

    const [currentGalleryIndex, setCurrentGalleryIndex] = useState<number>(0);

    const galleryAssets = contentModel.reflist('header_assets');

    const gallery360Assets = contentModel
        .reflist('header_assets')
        .filter(asset => asset.asModel.isinStrlist('tags', 'abholer_gallery_360'));

    const [is360active, set360active] = useState(false);

    const currentAssets = is360active ? gallery360Assets : galleryAssets;

    const indexChangeEventType = 'gallery-index-change';

    window.addEventListener(indexChangeEventType, e => {
        setCurrentGalleryIndex((e as CustomEvent).detail.index);
    });

    return (
        <>
            {gallery360Assets.length > 0 ||
                (galleryAssets.length > 0 && (
                    <div id='gallery'>
                        <h1>Galerie</h1>
                        <div className='gallery-swipeable-area'>
                            <button
                                className='gallery-switch-button left'
                                disabled={currentGalleryIndex === 0}
                                onClick={() => setCurrentGalleryIndex(Math.max(0, currentGalleryIndex - 1))}
                            >
                                <svg>
                                    <use xlinkHref='#chevron-narrow-right' />
                                </svg>
                            </button>

                            {is360active ? (
                                gallery360Assets.map((imageContent, index) => (
                                    <Panoramara
                                        key={`gallery-360-${index}`}
                                        id={`gallery-360-${index}`}
                                        alt='Ein 360° Bild des Autos'
                                        img={imageContent.asAsset.url}
                                    />
                                ))
                            ) : (
                                <SwipeableViews
                                    className={`${is360active ? 'wideView-active' : 'swipeable-view'}`}
                                    index={currentGalleryIndex}
                                    onChangeIndex={index => setCurrentGalleryIndex(index)}
                                >
                                    {galleryAssets.map((imageContent, index) => (
                                        <div
                                            key={`gallery- ${index}`}
                                            onClick={() => {
                                                setOverlay(store, {
                                                    images: galleryAssets,
                                                    imageIndex: currentGalleryIndex,
                                                    setImageIndexEventType: indexChangeEventType,
                                                });
                                            }}
                                        >
                                            <img
                                                className='gallery-img'
                                                src={imageContent.asAsset.url}
                                                alt={`gallery- ${index}`}
                                            />
                                            <svg>
                                                <use xlinkHref='#fullscreen-open' />
                                            </svg>
                                        </div>
                                    ))}
                                </SwipeableViews>
                            )}
                            <button
                                className='gallery-switch-button right'
                                disabled={
                                    currentAssets.length === 0 || currentGalleryIndex === currentAssets.length - 1
                                }
                                onClick={() =>
                                    setCurrentGalleryIndex(Math.min(currentAssets.length - 1, currentGalleryIndex + 1))
                                }
                            >
                                <svg>
                                    <use xlinkHref='#chevron-narrow-right' />
                                </svg>
                            </button>
                        </div>
                        <Indicators
                            length={currentAssets.length}
                            active={currentGalleryIndex + 1}
                            setActive={setCurrentGalleryIndex}
                        />
                        {/*
                        ------- Patrick wanted these buttons hidden for now ----------
                    */}
                        {/*<div*/}
                        {/*        className={`gallery-filter-wrapper ${is360active} ${*/}
                        {/*                is360active ? "panorama-active" : ""*/}
                        {/*        }`}*/}
                        {/*>*/}
                        {/*    <div*/}
                        {/*            className={`gallery-filter gallery-filter-normal ${*/}
                        {/*                    is360active ? "" : "active"*/}
                        {/*            }`}*/}
                        {/*            onClick={() => {*/}
                        {/*                set360active(false);*/}
                        {/*                setCurrentGalleryIndex(0);*/}
                        {/*            }}*/}
                        {/*    >*/}
                        {/*        <svg>*/}
                        {/*            <use xlinkHref="#filter-normal"/>*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*            className={`gallery-filter gallery-filter-360 ${*/}
                        {/*                    is360active ? "active" : ""*/}
                        {/*            }`}*/}
                        {/*            onClick={() => {*/}
                        {/*                set360active(true);*/}
                        {/*                setCurrentGalleryIndex(0);*/}
                        {/*            }}*/}
                        {/*    >*/}
                        {/*        <svg>*/}
                        {/*            <use xlinkHref="#filter-360"/>*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                ))}
        </>
    );
}
