import { Link } from 'react-router-dom';

export default function CustomLink(props: { children: React.ReactNode; to: string }): JSX.Element {
    const [link, hash] = props.to.split('#');

    return (
        <Link
            onClick={() => {
                history.replaceState(
                    '',
                    document.title,
                    location.pathname + location.search + location.hash.split('#', 2).join('#')
                );
            }}
            to={`${link}${hash != undefined ? `#${hash}` : ''}`}
        >
            {props.children}
        </Link>
    );
}
