import React, { useRef } from 'react';

import './panorama.scss';
import ReactPannellum from '../../ReactPanellum';

export default function Panoramara(props: { id: string; alt: string; img: string }): JSX.Element {
    const ref = useRef<ReactPannellum>(null);

    return (
        <div>
            <ReactPannellum
                //required:
                ref={ref}
                sceneId={`SceneId-${props.id}`}
                id={`Id-${props.id}`}
                className={`panorama`}
                imageSource={props.img}
                //optional:
                style={{
                    width: '100vw', //calc(100vw - 128px)
                    height: '400px',
                    background: '#000000',
                }}
                config={{
                    autoLoad: true,
                    preload: true,
                    showControls: true,
                    showZoomCtrl: false,
                    showFullscreenCtrl: true,
                    draggable: true,
                }}
                alt={props.alt}
            />
        </div>
    );
}
