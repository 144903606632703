import React, { useContext } from 'react';
import Page from '../Page';
import { OverlayEnum, PageChildrenEnum } from '../../enums';
import './styles.scss';

import Overview from './Sections/Overview';
import Gallery from './Sections/Gallery';
import Details from './Sections/Details';
import Assistants from './Sections/Assistants';
import InfoArea from './Sections/InfoArea';
import SspProviderContext from '../../utils/SspProvider/context';
import { setOverlay } from '../../utils/SspProvider/dispatchFunctions';
import FurtherLinks from './Sections/FurtherLinks';
import Highlights from './Sections/Highlights';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { getAssistants } from './utils/getAssistants';
import { getHighlights } from './utils/getHighlights';

export default function Expose(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const engineType = contentModel.ref('engine').asModel.str('engine_type');

    return (
        <Page child={PageChildrenEnum.Expose}>
            <div className='expose'>
                <Overview />
                {getHighlights(contentModel).length > 0 && <Highlights />}
                <Gallery />
                <Details />
                {getAssistants(contentModel).length > 0 && <Assistants />}
                <InfoArea elementID={'media-center'} />
                <InfoArea elementID={'we-connect'} />
                {engineType === 'Electric' && (
                    <>
                        <InfoArea elementID={'e-mobility'} />
                        <FurtherLinks />
                    </>
                )}

                {/*<div id='disclaimer'>*/}
                {/*    <h1>Volkswagen AG Disclaimer</h1>*/}
                {/*    <table>*/}
                {/*        <tbody>*/}
                {/*        <tr>*/}
                {/*            <td>*/}
                {/*                Die angegebenen Verbrauchs- und Emissionswerte wurden nach den gesetzlich*/}
                {/*                vorgeschriebenen Messverfahren ermittelt. Seit dem 1. September 2017 werden*/}
                {/*                bestimmte*/}
                {/*                Neuwagen bereits nach dem weltweit harmonisierten Prüfverfahren für Personenwagen*/}
                {/*                und*/}
                {/*                leichte Nutzfahrzeuge (Worldwide Harmonized Light Vehicles Test Procedure, WLTP),*/}
                {/*                einem*/}
                {/*                realistischeren Prüfverfahren zur Messung des Kraftstoffverbrauchs und der*/}
                {/*                CO2-Emissionen, typgenehmigt. Ab dem 1. September 2018 wird der WLTP schrittweise*/}
                {/*                den*/}
                {/*                neuen europäischen Fahrzyklus (NEFZ) ersetzen. Wegen der realistischeren*/}
                {/*                Prüfbedingungen*/}
                {/*                sind die nach dem WLTP gemessenen Kraftstoffverbrauchs- und CO2-Emissionswerte in*/}
                {/*                vielen*/}
                {/*                Fällen höher als die nach dem NEFZ gemessenen. Dadurch können sich ab 1. September*/}
                {/*                2018*/}
                {/*                bei der Fahrzeugbesteuerung entsprechende Änderungen ergeben. Weitere Informationen*/}
                {/*                zu*/}
                {/*                den Unterschieden zwischen WLTP und NEFZ finden Sie unter www.volkswagen.de/wltp.*/}
                {/*                <p/>*/}
                {/*                <br/>*/}
                {/*                Aktuell sind noch die NEFZ-Werte verpflichtend zu kommunizieren. Soweit es sich um*/}
                {/*                Neuwagen handelt, die nach WLTP typgenehmigt sind, werden die NEFZ-Werte von den*/}
                {/*                WLTP-Werten abgeleitet. Die zusätzliche Angabe der WLTP-Werte kann bis zu deren*/}
                {/*                verpflichtender Verwendung freiwillig erfolgen. Soweit die NEFZ-Werte als Spannen*/}
                {/*                angegeben werden, beziehen sie sich nicht auf ein einzelnes, individuelles Fahrzeug*/}
                {/*                und*/}
                {/*                sind nicht Bestandteil des Angebotes. Sie dienen allein Vergleichszwecken zwischen*/}
                {/*                den*/}
                {/*                verschiedenen Fahrzeugtypen. Zusatzausstattungen und Zubehör (Anbauteile,*/}
                {/*                Reifenformat*/}
                {/*                usw.) können relevante Fahrzeugparameter, wie z. B. Gewicht, Rollwiderstand und*/}
                {/*                Aerodynamik, verändern und neben Witterungs- und Verkehrsbedingungen sowie dem*/}
                {/*                individuellen Fahrverhalten den Kraftstoffverbrauch, den Stromverbrauch, die*/}
                {/*                CO2-Emissionen und die Fahrleistungswerte eines Fahrzeugs beeinflussen.Weitere*/}
                {/*                Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen, spezifischen*/}
                {/*                CO2-Emissionen neuer Personenkraftwagen können dem „Leitfaden über den*/}
                {/*                Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer*/}
                {/*                Personenkraftwagen“*/}
                {/*                entnommen werden, der an allen Verkaufsstellen und bei der DAT Deutsche Automobil*/}
                {/*                Treuhand GmbH, Hellmuth-Hirth-Str. 1, 73760 Ostfildern-Scharnhausen (www.dat.de/co2)*/}
                {/*                unentgeltlich erhältlich ist.*/}
                {/*                <p/><br/>*/}
                {/*                Effizienzklassen bewerten Fahrzeuge anhand der*/}
                {/*                CO2-Emissionen unter Berücksichtigung des Fahrzeugleergewichts. Fahrzeuge, die dem*/}
                {/*                Durchschnitt entsprechen, werden mit D eingestuft. Fahrzeuge, die besser sind als*/}
                {/*                der*/}
                {/*                heutige Durchschnitt werden mit A+++, A++, A+, A, B oder C eingestuft. Fahrzeuge,*/}
                {/*                die*/}
                {/*                schlechter als der Durchschnitt sind, werden mit E, F oder G beschrieben. Die hier*/}
                {/*                gemachten Angaben beziehen sich jeweils auf die EG-Typgenehmigung des gewählten*/}
                {/*                Modells*/}
                {/*                und dessen Serienausstattung gem. Richtlinie 2007/46/EG. Von Ihnen im Zuge der*/}
                {/*                Konfiguration gewählte Sonderausstattung kann dazu führen, dass Ihr konfiguriertes*/}
                {/*                Modell aufgrund der gewählten Ausstattung einem anderen genehmigten Typ entspricht,*/}
                {/*                als*/}
                {/*                dies ohne gewählte Sonderausstattung der Fall wäre. Daraus können sich Abweichungen*/}
                {/*                der*/}
                {/*                Angaben für Ihr konfiguriertes Modell ergeben. Bei den angegebenen CO2-Werten*/}
                {/*                handelt es*/}
                {/*                sich um die Werte, die im Rahmen der Typgenehmigung des Fahrzeugs ermittelt wurden.*/}
                {/*            </td>*/}
                {/*        </tr>*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*</div>*/}

                <div className='floating-buttons-container'>
                    <button
                        className='floating-button'
                        onClick={() => setOverlay(store, { enum: OverlayEnum.ShareExpose })}
                    >
                        <svg>
                            <use xlinkHref='#share' />
                        </svg>
                    </button>
                    <a
                        target={'_blank'}
                        rel={'noreferrer'}
                        href='https://userguide.volkswagen.de/public/vin/login/de_DE'
                    >
                        <button className='floating-button'>
                            <svg>
                                <use xlinkHref='#manual' />
                            </svg>
                        </button>
                    </a>
                </div>
            </div>
        </Page>
    );
}
