import React, { useContext } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { getAssistants } from '../utils/getAssistants';

export default function Assistants(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const assistants = getAssistants(contentModel);

    return (
        <div id='assistants'>
            <h1>Assistenzsysteme</h1>
            <p>
                Sie möchten alle Features zu Ihrem Fahrzeug einsehen? Dann sind Sie hier genau an der richtigen Stelle:
            </p>
            <button
                className='to-the-assistants'
                onClick={() => {
                    setOverlay(store, {
                        assistants: assistants,
                        title: 'Assistenzsysteme',
                    });
                }}
            >
                Meine Assistenzsysteme
            </button>
        </div>
    );
}
