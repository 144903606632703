export enum PageChildrenEnum {
    Expose,
    PickUp,
    Map,
    Back,
    CheckIn,
    Overlay,
    None,
    Closeable,
}

export enum CarDetailEnum {
    Overview,
    // TechData,
    Standard,
    Optional,
}

export enum OverlayEnum {
    HomeScreen,
    ShareExpose,
    InfoChecklist,
    AdditionalInfo,
    InfoRental,
    Hotline,
    ValueCredit,
    Loading,
    WrongVIN,
    ExternalContent,
}

export enum CloseableEnum {
    Privacy,
    Imprint,
    Opening,
    Contact,
}
