import React from 'react';

export default function FurtherLinks(): JSX.Element {
    return (
        <div id='further-links'>
            <h1>Top-Links:</h1>
            <a href='https://www.volkswagen.de/de/elektrofahrzeuge.html' target='_blank' rel='noreferrer'>
                <p className='link'>Insights zum Thema Laden</p>
            </a>
            <a
                href='https://www.volkswagen.de/de/elektrofahrzeuge/laden-und-reichweite/routenplaner.html'
                target='_blank'
                rel='noreferrer'
            >
                <p className='link'>Routenplaner</p>
            </a>
            <a href='https://www.volkswagen.at/modelle/technologie' target='_blank' rel='noreferrer'>
                <p className='link'>Die wichtigsten Begriffe der Elektro-Mobilität</p>
            </a>
        </div>
    );
}
